<template>
  <div class="citydetail-container position-relative">
    <div class="sale-font mb-25 font-weight text-center">
      打款申请单号：{{ $empty.empty(merchantDetails.withdraw_no) }}
    </div>
    <div class="orderdetail-wrapper">
      <el-form
        :model="merchantDetails"
        ref="addForm"
        :inline="true"
        :hide-required-asterisk="true"
      >
        <div>
          <el-row :gutter="20">
            <el-col :span="8">
              <el-form-item label="城市仓" prop="title">
                <div>
                  <span>{{ merchantDetails?.logistics_name || "-" }}</span>
                  <span
                    v-if="
                      merchantDetails.is_withdrawal === IS_WITHDRAWAL.no.value
                    "
                    class="withdrawal_tag"
                    >{{ `【${IS_WITHDRAWAL.no.label}】` }}</span
                  >
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="可提现总金额" prop="bank_deposit">
                {{
                  tool.toDecimal2(
                    (merchantDetails.avail_amount * 10000) / 10000 / 10000
                  )
                }}
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="申请提现金额" prop="card_code">
                <div>
                  {{
                    tool.toDecimal2(
                      (merchantDetails.money * 10000) / 100 / 100 / 10000
                    )
                  }}
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="开户行" prop="money">
                <span v-if="merchantDetails.withdraw_bank">{{
                  merchantDetails.withdraw_bank.bank_deposit
                }}</span>
                <span v-else>—</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="打款账户" prop="carLicense">
                <span v-if="merchantDetails.withdraw_bank">{{
                  merchantDetails.withdraw_bank.card_code
                }}</span>
                <span v-else>—</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="持卡人姓名" prop="username">
                <span v-if="merchantDetails.withdraw_bank">{{
                  merchantDetails.withdraw_bank.username
                }}</span>
                <span v-else>—</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="联系电话" prop="mobile">
                <span v-if="merchantDetails.business_bank">{{
                  merchantDetails.business_bank.mobile
                }}</span>
                <span v-else>—</span>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="提现类型" prop="withdraw_type">
                <span v-if="merchantDetails.withdraw_type">
                  <span v-if="merchantDetails.withdraw_type == 1"
                    >运费提现</span
                  >
                  <span v-else>服务费提现</span></span
                >
                <span v-else>—</span>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-if="merchantDetails.refuse_remarks">
              <el-form-item
                :label="
                  Number(status) === PAY_STATUS.fail.value
                    ? '拒绝原因'
                    : '退回原因'
                "
                prop="refuse_remarks"
              >
                <div>
                  {{
                    $empty.empty($empty.empty(merchantDetails.refuse_remarks))
                  }}
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="发票信息" prop="warehouseId">
                <el-image
                  class="kvimg"
                  :src="merchantDetails.receipt"
                  :preview-src-list="[merchantDetails.receipt]"
                >
                  <div slot="error" class="image-slot">
                    <i class="el-icon-picture-outline"></i>
                  </div>
                </el-image>
                <!-- <img class="kvimg" :src="merchantDetails.receipt" alt=""> -->
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-form-item label="打款凭证" prop="warehouseId">
                <el-upload
                  action="#"
                  :on-preview="handlePictureCardPreview"
                  :class="{ hide: imageUrl.length == 1 || !imageUrl }"
                  :file-list="imageUrl"
                  list-type="picture-card"
                  :http-request="uploadFile"
                  :before-remove="deleteFile"
                  :limit="1"
                >
                  <i slot="trigger" class="el-icon-plus"></i>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <!--  审核拒绝弹出框  -->
    <el-dialog
      title="拒绝打款"
      :visible.sync="dialogFormVisible"
      v-if="dialogFormVisible"
      width="550px"
      class="dialog"
      center
    >
      <div class="contents1">
        <!-- <div class="span">
            <div class="w"></div>
            填写拒绝打款原因
          </div> -->
        <el-form
          :model="auditFrom"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="" prop="refuseReason">
            <el-input
              type="textarea"
              v-model="auditFrom.refuseReason"
              :autosize="{ minRows: 10 }"
              placeholder="填写拒绝打款原因"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogFormVisible = false;
            auditFrom.refuseReason = '';
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="submitFrom">确认</el-button>
      </span>
    </el-dialog>
    <div v-show="showImage" class="shadow" @click="showImage = false"></div>
    <div v-show="showImage" class="bigImage">
      <img
        style="max-height: 100%; width: auto; height: auto"
        :src="bigImage"
        alt=""
      />
      <i class="el-icon el-icon-close bigClose" @click="showImage = false"></i>
    </div>
    <div class="fy1" style="position: absolute; bottom: 0; left: 0; right: 0">
      <el-button @click="$router.back()">返回</el-button>
      <!-- 1 || 2 银企直联 不显示操作按钮  -->
      <div
        style="display: inline-block; margin-left: 10px"
        v-if="
          merchantDetails.operate_type != 1 || merchantDetails.operate_type != 2
        "
      >
        <el-button
          v-if="isShowPay"
          type="success"
          @click="
            payType = 2;
            submitFrom();
          "
          >确定打款完成</el-button
        >
        <el-button
          v-if="Number(status) === PAY_STATUS.pass.value"
          type="success"
          @click="
            dialogFormVisible = true;
            payType = 4;
          "
          >打款退回</el-button
        >
        <el-button
          v-if="Number(status) === PAY_STATUS.todo.value"
          type="warning"
          @click="
            dialogFormVisible = true;
            payType = 3;
          "
          >拒绝打款</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from "element-ui";
import { IS_WITHDRAWAL, PAY_STATUS } from "./utils/enum/index";

export default {
  data() {
    return {
      merchantDetails: {},
      proName: {
        2: "确定打款吗？",
        3: "确定拒绝打款吗？",
        4: "确定打款退回吗？",
      },
      backName: {
        2: "已打款",
        3: "已拒绝",
        4: "已退回",
      },
      imageUrl: [],
      id: "",
      status: "",
      bigImage: "",
      payType: "",
      load: null,
      loadImage: null,
      detailsDialog: false,
      dialogFormVisible: false,
      IS_WITHDRAWAL,
      PAY_STATUS,
      auditFrom: {
        wantToBuyId: 0,
        auditState: 3,
        refuseReason: "",
      },
      rules: {
        refuseReason: [
          { required: true, message: "请填写拒绝原因", trigger: "blur" },
        ],
      },
      showImage: false,
    };
  },
  computed: {
    /**
     * 是否展示打款按钮
     * 1. 状态为待审核
     * 2. 城市仓未被禁用打款
     */
    isShowPay() {
      const first = Number(this.status) == PAY_STATUS.todo.value;
      const second =
        this.merchantDetails?.is_withdrawal === IS_WITHDRAWAL.yes.value;
      const res = first && second;
      return res;
    },
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      this.id = this.$route.query.id;
      this.getDetail();
    }
    if (this.$route.query && this.$route.query.status) {
      this.status = this.$route.query.status;
    }
  },
  methods: {
    // 获取详情
    getDetail() {
      this.$api.general
        .logisticsWithdrawDetail({ withdraw_id: this.id })
        .then((res) => {
          this.merchantDetails = res.data;
          this.imageUrl = res.data.payment_img
            ? [{ url: res.data.payment_img }]
            : [];
          this.loading = false;
        });
    },
    uploadFile(options) {
      this.loadImage = Loading.service({ fullscreen: false });
      // 拿到 file
      let file = options.file;
      let date = new Date().getTime();
      console.log(file, "文件");
      var event = event || window.event;
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (e) => {
        console.log(e.target.result);
        this.uploadImage(e.target.result);
      };
    },
    updatePaymentImg(img) {
      this.$api.general
        .uploadPaymentImg({
          withdraw_id: this.id,
          payment_img: img,
        })
        .then((res) => {})
        .catch((error) => {});
    },
    uploadImage(img) {
      this.$api.general
        .uploadImage({ file_data: img })
        .then((res) => {
          this.imageUrl = [{ url: res.data }];
          this.merchantDetails.payment_img = res.data;
          this.loadImage.close();
          this.updatePaymentImg(this.merchantDetails.payment_img);
        })
        .catch((error) => {
          this.imageUrl = [];
          this.loadImage.close();
        });
      console.log(this.imageUrl);
    },
    updatePaymentBack() {
      this.load = Loading.service({ fullscreen: true });
      this.$confirm(`确定打款退回吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.$api.general
            .updatePaymentBack({
              id: this.id,
              refuse_remarks: this.auditFrom.refuseReason,
            })
            .then((res) => {
              this.tool.message("已退回", "success");
              this.load.close();
              this.$router.back();
            });
        })
        .catch(() => {
          this.load.close();
        });
    },
    handlePictureCardPreview(file) {
      this.bigImage = file.url;
      this.showImage = true;
    },
    deleteFile(file, fileList) {
      console.log(file, "删除");
      console.log(fileList, "删除");
      this.imageUrl = fileList;
      this.merchantDetails.payment_img = "";
      console.log(this.imageUrl);
      // this.updatePaymentImg(this.merchantDetails.payment_img)
    },
    // 审核不通过
    auditFail(index, row) {
      this.dialogFormVisible = true;
    },
    submitFrom() {
      this.$confirm(`${this.proName[this.payType]}`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      })
        .then(() => {
          this.load = Loading.service({ fullscreen: true });
          this.$api.general
            .withdrawAuditPayment({
              withdraw_id: this.id,
              status: this.payType, //2：通过，3：拒绝
              payment_img: this.merchantDetails.payment_img,
              refuse_remarks: this.auditFrom.refuseReason,
            })
            .then((res) => {
              this.load.close();
              this.tool.message(`${this.backName[this.payType]}`, "success");
              this.dialogFormVisible = false;
              this.$router.back();
            })
            .catch((error) => {
              this.dialogFormVisible = false;
              this.load.close();
            });
        })
        .catch(() => {});
    },
    sureConfirm() {
      console.log("123");
      let load = Loading.service({ fullscreen: false });
      this.tool.debounce(() => {
        this.$api.general
          .updateSupplier({
            id: this.id,
            payment_img: this.merchantDetails.payment_img,
            status: PAY_STATUS.pass.value, //2：通过，3：拒绝
            refuse_remarks: "",
          })
          .then((res) => {
            load.close();
            this.tool.message("已打款", "success");
            this.$store.commit("setRefresh", true);
            setTimeout(() => {
              this.$router.back();
            }, 800);
          })
          .catch((error) => {
            load.close();
          });
      });
    },
  },
};
</script>

<style lang="scss">
.withdrawal_tag {
  color: $danger-color;
}

.citydetail-container {
  .el-form-item.is-no-asterisk {
    display: flex;
  }

  .image-slot {
    text-align: center;
    background: #f5f7fa;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }

  .el-col-24 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .contents1 {
    box-sizing: border-box;
    padding: 40px 26px;

    .span {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #349ee6;
      padding: 4px 13px 4px 0;
      box-sizing: border-box;
      background: #f5f7fa;
      display: flex;
      align-content: center;
      margin-bottom: 20px;

      .w {
        width: 3px;
        background-color: #349ee6;
        margin-right: 13px;
      }
    }

    .el-form-item__content {
      margin-left: 0px !important;
    }
  }

  .orderdetail-wrapper {
    .el-form--inline .el-form-item__label {
      width: 125px;
    }
  }

  .kvimg {
    width: 314px;
    height: 196px;
  }

  .hide {
    .el-upload--picture-card {
      display: none;
    }
  }

  .sale-font {
    font-size: 15px;
  }

  .el-upload-list--picture-card .el-upload-list__item {
    width: 314px;
    height: 196px;
  }

  padding: 10px;
  background-color: #ffffff;
  box-sizing: border-box;
  min-height: calc(100% - 20px);

  .warehouse-wrapper {
    width: 302px;
    height: 37px;
    line-height: 37px;
    text-align: center;
    background: #f2fcff;
    border-radius: 2px;
    border: 1px dashed #349ee6;
    margin-bottom: 20px;
    cursor: pointer;

    .plus {
      width: 14px;
      height: 14px;
    }
  }

  .warehouse-container {
    .warehouse-box {
      border-radius: 2px;
      border: 1px solid #dfe3ef;
      margin-bottom: 20px;
      padding: 11px 13px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        line-height: 1;
      }

      .box-img {
        width: 14px;
        height: 14px;
        cursor: pointer;
      }
    }
  }

  .footerbtn {
    position: fixed;
    right: 20px;
    top: 13%;
  }

  .allmoney {
    position: fixed;
    left: 210px;
    right: 20px;
    height: 76px;
    padding: 0 30px;
    line-height: 76px;
    background: #fffcf9;
    box-shadow: 0px -3px 8px 0px rgba(255, 140, 89, 0.3);
    border-radius: 6px;
    bottom: 40px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
  }

  .allmoney-font {
    font-size: 18px;
  }

  .auto-table-flex .el-table__footer-wrapper tbody td.el-table__cell {
    background-color: #eff6ff;
  }

  .auto-table-flex .el-table .el-table__footer-wrapper .cell {
    color: #e65a34;
  }
}
</style>
