var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "citydetail-container position-relative" },
    [
      _c("div", { staticClass: "sale-font mb-25 font-weight text-center" }, [
        _vm._v(
          " 打款申请单号：" +
            _vm._s(_vm.$empty.empty(_vm.merchantDetails.withdraw_no)) +
            " "
        ),
      ]),
      _c(
        "div",
        { staticClass: "orderdetail-wrapper" },
        [
          _c(
            "el-form",
            {
              ref: "addForm",
              attrs: {
                model: _vm.merchantDetails,
                inline: true,
                "hide-required-asterisk": true,
              },
            },
            [
              _c(
                "div",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "城市仓", prop: "title" } },
                            [
                              _c("div", [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.merchantDetails?.logistics_name || "-"
                                    )
                                  ),
                                ]),
                                _vm.merchantDetails.is_withdrawal ===
                                _vm.IS_WITHDRAWAL.no.value
                                  ? _c(
                                      "span",
                                      { staticClass: "withdrawal_tag" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            `【${_vm.IS_WITHDRAWAL.no.label}】`
                                          )
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "可提现总金额",
                                prop: "bank_deposit",
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.tool.toDecimal2(
                                      (_vm.merchantDetails.avail_amount *
                                        10000) /
                                        10000 /
                                        10000
                                    )
                                  ) +
                                  " "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "申请提现金额",
                                prop: "card_code",
                              },
                            },
                            [
                              _c("div", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.tool.toDecimal2(
                                        (_vm.merchantDetails.money * 10000) /
                                          100 /
                                          100 /
                                          10000
                                      )
                                    ) +
                                    " "
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "开户行", prop: "money" } },
                            [
                              _vm.merchantDetails.withdraw_bank
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.merchantDetails.withdraw_bank
                                          .bank_deposit
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "打款账户", prop: "carLicense" },
                            },
                            [
                              _vm.merchantDetails.withdraw_bank
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.merchantDetails.withdraw_bank
                                          .card_code
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "持卡人姓名", prop: "username" },
                            },
                            [
                              _vm.merchantDetails.withdraw_bank
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.merchantDetails.withdraw_bank
                                          .username
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "联系电话", prop: "mobile" } },
                            [
                              _vm.merchantDetails.business_bank
                                ? _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.merchantDetails.business_bank.mobile
                                      )
                                    ),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "提现类型",
                                prop: "withdraw_type",
                              },
                            },
                            [
                              _vm.merchantDetails.withdraw_type
                                ? _c("span", [
                                    _vm.merchantDetails.withdraw_type == 1
                                      ? _c("span", [_vm._v("运费提现")])
                                      : _c("span", [_vm._v("服务费提现")]),
                                  ])
                                : _c("span", [_vm._v("—")]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm.merchantDetails.refuse_remarks
                        ? _c(
                            "el-col",
                            { attrs: { span: 24 } },
                            [
                              _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label:
                                      Number(_vm.status) ===
                                      _vm.PAY_STATUS.fail.value
                                        ? "拒绝原因"
                                        : "退回原因",
                                    prop: "refuse_remarks",
                                  },
                                },
                                [
                                  _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$empty.empty(
                                            _vm.$empty.empty(
                                              _vm.merchantDetails.refuse_remarks
                                            )
                                          )
                                        ) +
                                        " "
                                    ),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "发票信息", prop: "warehouseId" },
                            },
                            [
                              _c(
                                "el-image",
                                {
                                  staticClass: "kvimg",
                                  attrs: {
                                    src: _vm.merchantDetails.receipt,
                                    "preview-src-list": [
                                      _vm.merchantDetails.receipt,
                                    ],
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "image-slot",
                                      attrs: { slot: "error" },
                                      slot: "error",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-picture-outline",
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "打款凭证", prop: "warehouseId" },
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  class: {
                                    hide:
                                      _vm.imageUrl.length == 1 || !_vm.imageUrl,
                                  },
                                  attrs: {
                                    action: "#",
                                    "on-preview": _vm.handlePictureCardPreview,
                                    "file-list": _vm.imageUrl,
                                    "list-type": "picture-card",
                                    "http-request": _vm.uploadFile,
                                    "before-remove": _vm.deleteFile,
                                    limit: 1,
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus",
                                    attrs: { slot: "trigger" },
                                    slot: "trigger",
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _vm.dialogFormVisible
        ? _c(
            "el-dialog",
            {
              staticClass: "dialog",
              attrs: {
                title: "拒绝打款",
                visible: _vm.dialogFormVisible,
                width: "550px",
                center: "",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.dialogFormVisible = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "contents1" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "ruleForm",
                      staticClass: "demo-ruleForm",
                      attrs: { model: _vm.auditFrom, "label-width": "100px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "refuseReason" } },
                        [
                          _c("el-input", {
                            attrs: {
                              type: "textarea",
                              autosize: { minRows: 10 },
                              placeholder: "填写拒绝打款原因",
                            },
                            model: {
                              value: _vm.auditFrom.refuseReason,
                              callback: function ($$v) {
                                _vm.$set(_vm.auditFrom, "refuseReason", $$v)
                              },
                              expression: "auditFrom.refuseReason",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.dialogFormVisible = false
                          _vm.auditFrom.refuseReason = ""
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitFrom },
                    },
                    [_vm._v("确认")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showImage,
            expression: "showImage",
          },
        ],
        staticClass: "shadow",
        on: {
          click: function ($event) {
            _vm.showImage = false
          },
        },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showImage,
              expression: "showImage",
            },
          ],
          staticClass: "bigImage",
        },
        [
          _c("img", {
            staticStyle: {
              "max-height": "100%",
              width: "auto",
              height: "auto",
            },
            attrs: { src: _vm.bigImage, alt: "" },
          }),
          _c("i", {
            staticClass: "el-icon el-icon-close bigClose",
            on: {
              click: function ($event) {
                _vm.showImage = false
              },
            },
          }),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "fy1",
          staticStyle: {
            position: "absolute",
            bottom: "0",
            left: "0",
            right: "0",
          },
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$router.back()
                },
              },
            },
            [_vm._v("返回")]
          ),
          _vm.merchantDetails.operate_type != 1 ||
          _vm.merchantDetails.operate_type != 2
            ? _c(
                "div",
                {
                  staticStyle: {
                    display: "inline-block",
                    "margin-left": "10px",
                  },
                },
                [
                  _vm.isShowPay
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              _vm.payType = 2
                              _vm.submitFrom()
                            },
                          },
                        },
                        [_vm._v("确定打款完成")]
                      )
                    : _vm._e(),
                  Number(_vm.status) === _vm.PAY_STATUS.pass.value
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "success" },
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = true
                              _vm.payType = 4
                            },
                          },
                        },
                        [_vm._v("打款退回")]
                      )
                    : _vm._e(),
                  Number(_vm.status) === _vm.PAY_STATUS.todo.value
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "warning" },
                          on: {
                            click: function ($event) {
                              _vm.dialogFormVisible = true
                              _vm.payType = 3
                            },
                          },
                        },
                        [_vm._v("拒绝打款")]
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }